export default {
  props: {
    headline: {
      type: String,
      required: true,
      default: "",
    },

    headlineSize: {
      type: String,
      required: false,
      default: "3",
    },

    subHeadline: {
      type: String,
      required: false,
      default: "",
    },
  },
};
