import i18n from "../i18n";

export default {
  OPEN: {
    key: "open",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.OPEN"),
    color: "#00f",
  },
  NEED_FOR_ACTION: {
    key: "needForAction",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.NEED_FOR_ACTION"),
    color: "#ff0",
  },
  ACCEPTED: {
    key: "accepted",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.ACCEPTED"),
    color: "#0f0",
  },
  CANCELLED: {
    key: "cancelled",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.CANCELLED"),
    color: "#f00",
  },
};
