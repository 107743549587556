<template>
  <BaseHover v-model="hover">
    <v-icon @click.prevent="goTo" class="clickable" :class="{ 'black--text': hover }">
      far fa-chevron-right
    </v-icon>
  </BaseHover>
</template>

<script>
import linkSwitchMethods from "@/mixins/linkSwitchMethods";
import linkSwitchProps from "@/mixins/linkSwitchProps";

export default {
  name: "CardLink",

  data() {
    return {
      hover: false,
    };
  },

  mixins: [linkSwitchMethods, linkSwitchProps],
};
</script>
