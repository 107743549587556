<template>
  <div v-if="hasOptions">
    <OptionRow
      v-for="(option, index) of options"
      :key="index"
      :option="option"
      :link="option.link"
    />
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import OptionRow from "./partials/OptionRow.vue";

export default {
  name: "OptionsBlock",

  components: {
    OptionRow,
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [
        {
          icon: "",
          label: "",
          link: () => ({}),
        },
      ],
    },
  },

  computed: {
    hasOptions() {
      return !isEmpty(this.options);
    },
  },
};
</script>
