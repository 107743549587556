<template>
  <v-sheet elevation="2" class="rounded overflow-hidden">
    <CardHeader :category="category">
      <template #headerRight>
        <slot name="headerRight" />
      </template>
    </CardHeader>

    <v-row class="px-6 pt-4" no-gutters v-if="hasMetaLeft">
      <v-col cols="auto" align-self="center">
        <slot name="metaLeft" />
      </v-col>

      <v-spacer v-if="hasMetaRight" />

      <v-col cols="auto" v-if="hasMetaRight" align-self="center">
        <slot name="metaRight" />
      </v-col>
    </v-row>

    <v-row no-gutters class="px-6 py-4">
      <v-col cols="auto" :lg="hasContentRight || showContentLink ? '8' : 'auto'">
        <v-row no-gutters>
          <v-col cols="12" align-self="center">
            <CardHeadline
              :headline="headline"
              :subHeadline="subHeadline"
              :headlineSize="headlineSize"
            />
          </v-col>

          <v-col cols="12">
            <slot name="content" />
          </v-col>
        </v-row>
      </v-col>

      <v-spacer v-if="showContentLink || hasContentRight" />

      <v-col cols="12" lg="3" v-if="hasContentRight">
        <slot name="contentRight" />
      </v-col>

      <v-col cols="4" align-self="center" class="text-right" v-if="showContentLink">
        <CardLink :link="link" />
      </v-col>
    </v-row>

    <div v-if="hasEvents">
      <BaseDivider margin="0" />
      <slot name="events" />
    </div>

    <div v-if="hasOptions">
      <BaseDivider margin="0" />
      <slot name="options" />
    </div>
  </v-sheet>
</template>

<script>
import categoryProps from "./mixins/categoryProps";
import headlineProps from "./mixins/headlineProps";
import linkSwitchProps from "@/mixins/linkSwitchProps";
import CardHeader from "./partials/CardHeader.vue";
import CardHeadline from "./partials/CardHeadline.vue";
import CardLink from "./partials/CardLink.vue";

export default {
  name: "SeminarCard",

  components: {
    CardHeader,
    CardHeadline,
    CardLink,
  },

  mixins: [categoryProps, headlineProps, linkSwitchProps],

  props: {
    showContentLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasEvents() {
      return !!this.$slots.events;
    },

    hasMetaLeft() {
      return !!this.$slots.metaLeft;
    },

    hasMetaRight() {
      return !!this.$slots.metaRight;
    },

    hasOptions() {
      return !!this.$slots.options;
    },

    hasContentRight() {
      return !!this.$slots.contentRight;
    },
  },
};
</script>
