<template>
  <SeminarCard
    :headline="seminar.title"
    :category="seminar.seminarFieldOfAction"
    class="mb-12"
    :link="{
      to: {
        name: 'seminar-speaker-my-contracts-show',
        params: { seminarId: this.seminar.id },
      },
    }"
  >
    <template #content v-if="seminar.description">
      <DescriptionBlock :description="seminar.description" />
    </template>

    <template #events>
      <EventRowLean>
        <template #date>
          <BaseSeminarDate :startAt="seminar.startAt" :endAt="seminar.endAt" />
        </template>

        <template #location>
          <BaseSeminarLocation
            :seminarId="seminar.id"
            :seminarTypeId="seminar.seminarType.id"
            verbose
          />
        </template>
      </EventRowLean>

      <NeedForActionIndicator :seminarId="seminar.id" :group="group" />
    </template>

    <template #options>
      <OptionsBlock :options="options" />
    </template>
  </SeminarCard>
</template>

<script>
import SeminarCard from "@/components/SeminarCard";
import EventRowLean from "@components/SeminarCardBuildingBlocks/EventRowLean";
import OptionsBlock from "@components/SeminarCardBuildingBlocks/OptionBlock";
import DescriptionBlock from "@components/SeminarCardBuildingBlocks/DescriptionBlock";
import NeedForActionIndicator from "../../../../../../../components/SeminarCardBuildingBlocks/NeedForActionIndicator.vue";

export default {
  name: "SeminarCardSpeaker",

  components: {
    NeedForActionIndicator,
    DescriptionBlock,
    OptionsBlock,
    EventRowLean,
    SeminarCard,
  },

  props: {
    seminar: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
      default: () => ({
        key: null,
        name: null,
        color: null,
      }),
    },
  },

  computed: {
    options() {
      return [
        {
          icon: "fas fa-file-contract",
          label: this.$t("SEMINAR_SPEAKER.CONTRACTS.SEMINAR_LINK"),
          link: {
            to: {
              name: "seminar-speaker-my-contracts-show",
              params: { seminarId: this.seminar.id },
            },
          },
        },
      ];
    },
  },
};
</script>
