<template>
  <BaseHover v-model="hover">
    <a @click.prevent="goTo">
      <v-row no-gutters class="px-6 py-3" :class="{ gray200: hover }">
        <v-col cols="auto" align-self="center" class="mr-2 text-center" style="min-width: 48px">
          <v-icon v-if="!hasIcon">
            {{ option.icon }}
          </v-icon>

          <div v-if="hasIcon">
            <slot name="icon" />
          </div>
        </v-col>

        <v-col align-self="center" cols="auto" class="pt-2">
          <BaseText v-if="!hasLabel">
            {{ option.label }}
          </BaseText>

          <BaseText v-if="hasLabel" bold>
            <slot name="label" />
          </BaseText>
        </v-col>
      </v-row>
    </a>
  </BaseHover>
</template>

<script>
import linkSwitchMethods from "@/mixins/linkSwitchMethods";
import linkSwitchProps from "@/mixins/linkSwitchProps";

export default {
  name: "OptionRow",

  mixins: [linkSwitchMethods, linkSwitchProps],

  data() {
    return {
      hover: false,
    };
  },

  props: {
    option: {
      type: Object,
      required: true,
      default: () => ({
        label: "",
        icon: "",
      }),
    },
  },

  computed: {
    hasIcon() {
      return !!this.$slots.date;
    },

    hasLabel() {
      return !!this.$slots.label;
    },
  },
};
</script>
