<template>
  <div>
    <v-row v-if="shouldShowIndicator" no-gutters class="px-6 pb-6">
      <v-col v-if="isConfirmed" cols="auto" class="d-flex">
        <v-icon color="success" left small> fas fa-check </v-icon>

        <BaseText type="p" class="mb-0 ml-1">
          {{ $t("SEMINAR_CARD.NEED_FOR_ACTION.CONFIRMED") }}
        </BaseText>
      </v-col>

      <v-col v-if="isCancelled" cols="auto" class="d-flex">
        <v-icon color="error" left small> fas fa-times </v-icon>

        <BaseText type="p" class="mb-0 ml-1">
          {{ $t("SEMINAR_CARD.NEED_FOR_ACTION.CANCELLED") }}
        </BaseText>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import speakerContractStatusGroups from "../../constants/speakerContractStatusGroups";
import contractStatusActions from "../../data/contractStatusActions";

export default {
  name: "NeedForActionIndicator",

  props: {
    group: {
      type: Object,
      required: true,
      default: () => ({
        key: null,
        name: null,
        color: null,
      }),
    },
    seminarId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      contractAction: null,
    };
  },

  computed: {
    shouldShowIndicator() {
      return this.groupMatches && !this.isLoading && this.contractAction;
    },

    groupMatches() {
      return this.group === speakerContractStatusGroups.NEED_FOR_ACTION;
    },

    isConfirmed() {
      return this.contractAction && this.contractAction.action === contractStatusActions.CONFIRM;
    },

    isCancelled() {
      return this.contractAction && this.contractAction.action === contractStatusActions.CANCEL;
    },
  },

  methods: {
    async getSpeakerContractAction() {
      this.isLoading = true;

      try {
        let response = await this.$axios.seminarManagement.listContracts(this.seminarId);
        const contract = response.data.data[0] || null;

        if (!contract) {
          this.isLoading = false;

          return;
        }

        response = await this.$axios.seminarManagement.listContractActions(contract.id);
        this.contractAction = response.data.data[0] || null;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (this.groupMatches) {
      this.getSpeakerContractAction();
    }
  },
};
</script>
