export default {
  props: {
    category: {
      type: Object,
      required: true,
      default: () => ({
        color: "#0069B4",
        description: "",
      }),
    },
  },
};
