<template>
  <div v-html="descriptionShort"></div>
</template>

<script>
import stringShortener from "@helper/stringShortenerHelper";

export default {
  name: "DescriptionBlock",

  props: {
    description: {
      type: String,
      required: true,
      default: "",
    },

    maxLength: {
      type: [Number, String],
      required: false,
      default: 160,
    },
  },

  computed: {
    descriptionShort() {
      if (!this.description) return "";

      return stringShortener(this.description, parseInt(this.maxLength, 10));
    },
  },
};
</script>
