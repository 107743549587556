<template>
  <div>
    <BaseHeadline :size="headlineSize" :class="{ 'mb-4': !subHeadline }">
      {{ headline }}
    </BaseHeadline>

    <BaseHeadline size="4" color="gray600" class="mt-2 mb-4" v-if="subHeadline">
      {{ subHeadline }}
    </BaseHeadline>
  </div>
</template>

<script>
import headlineProps from "../mixins/headlineProps";

export default {
  name: "CardHeadline",

  mixins: [headlineProps],
};
</script>
