<template>
  <v-row no-gutters class="px-6 py-6">
    <v-col cols="auto" class="pr-6" :class="{ 'mb-2': mdAndDown }">
      <BaseText bold v-if="!hasDate">
        {{ date }}
      </BaseText>

      <BaseText bold v-if="hasDate">
        <slot name="date" />
      </BaseText>
    </v-col>

    <v-col cols="12" lg="6">
      <BaseText v-if="!hasLocation">
        {{ location }}
      </BaseText>

      <BaseText v-if="hasLocation">
        <slot name="location" />
      </BaseText>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EventRowLean",

  props: {
    date: {
      type: String,
      required: false,
    },

    location: {
      type: String,
      required: false,
    },
  },

  computed: {
    hasDate() {
      return !!this.$slots.date;
    },

    hasLocation() {
      return !!this.$slots.location;
    },

    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
};
</script>
