<template>
  <div v-if="hasSeminars">
    <div class="d-flex mb-8 align-center">
      <BaseHeadline size="3" fake="4" class="mr-md-4 gray600--text">
        {{ group.name }}
      </BaseHeadline>

      <v-divider class="d-none d-md-block" />
    </div>

    <SeminarCardSpeaker
      v-for="seminar of seminars"
      :key="seminar.id"
      :seminar="seminar"
      :group="group"
    />
  </div>

  <div v-else></div>
</template>

<script>
import SeminarCardSpeaker from "./SeminarCardSpeaker";

export default {
  name: "SeminarList",

  components: {
    SeminarCardSpeaker,
  },

  props: {
    group: {
      type: Object,
      required: true,
      default: () => ({
        key: null,
        name: null,
        color: null,
      }),
    },
  },

  data() {
    return {
      isLoading: true,
      seminars: [],
      options: {},
    };
  },

  methods: {
    async getSeminarContracts() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarSpeakerSeminars({
          page: this.options.page,
          perPage: this.options.perPage,
          speakerContractStatusGroup: this.$props.group.key,
          sortByStatusChange: true,
        });
        this.seminars = data.data;

        this.$emit("loaded", data.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    hasSeminars() {
      return this.seminars.length > 0;
    },
  },

  created() {
    this.getSeminarContracts();
  },
};
</script>
