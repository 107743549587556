/**
 *
 * Shortens a string if it exceeds the max length
 *
 * @param {string} string
 * @param {number} maxLength
 * @param {boolean} appendix
 * @returns string
 */
const stringShortenerHelper = (string, maxLength = 120, appendix = true) => {
  let trimmedString = string.substr(0, maxLength);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
  );

  if (string.length >= maxLength) {
    return `${trimmedString}${appendix ? "..." : ""}`;
  }

  return string;
};

export default stringShortenerHelper;
