<template>
  <ModuleSubPage fullWidth title="SEMINAR_SPEAKER.CONTRACTS.TITLE">
    <BaseHasPermission :permissionProps="['isSpeaker']">
      <SeminarList
        :group="speakerContractStatusGroups.NEED_FOR_ACTION"
        @loaded="setResults(speakerContractStatusGroups.NEED_FOR_ACTION, $event)"
        class="mt-2"
      />

      <SeminarList
        :group="speakerContractStatusGroups.OPEN"
        @loaded="setResults(speakerContractStatusGroups.OPEN, $event)"
      />

      <SeminarList
        :group="speakerContractStatusGroups.ACCEPTED"
        @loaded="setResults(speakerContractStatusGroups.ACCEPTED, $event)"
      />

      <SeminarList
        :group="speakerContractStatusGroups.CANCELLED"
        @loaded="setResults(speakerContractStatusGroups.CANCELLED, $event)"
      />

      <BaseText v-if="hasNoResults">
        {{ $t("SEMINAR_SPEAKER.CONTRACTS.NO_CONTRACTS") }}
      </BaseText>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import SeminarList from "./partials/SeminarList.vue";
import speakerContractStatusGroups from "../../../../../constants/speakerContractStatusGroups";

export default {
  name: "SeminarSpeakerContractList",

  components: {
    SeminarList,
    ModuleSubPage,
  },

  data() {
    return {
      speakerContractStatusGroups,
      seminars: {},
      hasNoResults: false,
    };
  },

  methods: {
    setResults(group, seminars) {
      this.seminars[group.key] = seminars;

      // computed property could not handle object correctly
      this.checkHasResults();
    },

    checkHasResults() {
      const keys = Object.keys(this.seminars);

      if (keys.length < 4) {
        this.hasNoResults = false;

        return;
      }

      for (let key of keys) {
        if (this.seminars[key].length > 0) {
          this.hasNoResults = false;

          return;
        }
      }

      this.hasNoResults = true;
    },
  },
};
</script>
