<template>
  <v-row class="py-1 px-6" :style="style" no-gutters>
    <v-col cols="auto" align-self="center">
      <BaseHeadline size="4" color="white" class="pt-1">
        {{ category.description.toUpperCase() }}
      </BaseHeadline>
    </v-col>

    <v-spacer v-if="hasHeaderRight" />

    <v-col cols="auto" align-self="center" v-if="hasHeaderRight">
      <slot name="headerRight" />
    </v-col>
  </v-row>
</template>

<script>
import categoryProps from "../mixins/categoryProps";

export default {
  name: "CardHeader",

  mixins: [categoryProps],

  computed: {
    style() {
      return {
        backgroundColor: this.category.color || "#0069B4",
      };
    },

    hasHeaderRight() {
      return !!this.$slots.headerRight;
    },
  },
};
</script>
