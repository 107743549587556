<template>
  <v-row no-gutters>
    <v-col v-if="loading" cols="12">
      <v-progress-circular indeterminate color="primary" />
    </v-col>

    <v-col cols="12" v-if="showTitle">
      <BaseHeadline size="2" fake="3" class="mb-4">{{ $t(title) }}</BaseHeadline>
    </v-col>

    <v-col cols="12" v-if="showTitleSlot">
      <BaseHeadline size="2" fake="3" class="mb-4"><slot name="title" /></BaseHeadline>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      :md="fullWidth ? '12' : '10'"
      :lg="fullWidth ? '12' : '8'"
      :xl="fullWidth ? '12' : '6'"
      v-if="!loading"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ModuleSubPage",

  props: {
    title: {
      type: String,
      require: false,
      default: undefined,
    },

    fullWidth: {
      type: Boolean,
      require: false,
      default: false,
    },

    loading: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  computed: {
    showTitleSlot() {
      return !!this.$slots.title && !this.loading;
    },

    showTitle() {
      return !!this.title && !this.loading;
    },
  },
};
</script>
